import config from "./config";

// Warning: Experimental library, do not use in production environments.
import StackdriverErrorReporter from 'stackdriver-errors-js';

let errorHandler = null;

if (config.REACT_APP_GCP_ERROR_REPORTING_API_KEY) {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: config.REACT_APP_GCP_ERROR_REPORTING_API_KEY,
        projectId: config.REACT_APP_GOOGLE_CLOUD_PROJECT,
        service: config.REACT_APP_TOP_BAR_TITLE,
        version: config.REACT_APP_VERSION,
        reportUncaughtExceptions: true,
        reportUnhandledPromiseRejections: true,
        disabled: false,
    });
}

export default errorHandler;
