import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
let globalProposalSchema = null;

const api = {
    async downloadProposalForConversation(conversationId) {
        const response = await axios.get(`/conversation/${conversationId}/draft_proposal`, {responseType: "blob"})
        return response.data;
    },
    async getProposalSchema() {
        if (globalProposalSchema) {
            return globalProposalSchema;
        }
        const response = await axiosWithETAGCache.get(`/proposal/schema`);
        globalProposalSchema = response.data;
        return response.data;
    },
    async getProposals(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/proposal`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewProposal(newProposal) {
        const response = await axios.post(`/proposal`, newProposal);

        return response.data;
    },
    async saveProposal(changedProposal) {
        const response = await axios.put(`/proposal/${changedProposal._id}`, changedProposal);

        return response.data;
    },
    async getProposal(proposalId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/proposal/${proposalId}`, {
            params: queryParams
        });

        return response.data;
    },
    async deleteProposal(proposalId) {
        const response = await axios.delete(`/proposal/${proposalId}`);

        return response.data;
    },
    async renderProposal(proposalId) {
        const response = await axios.get(`/proposal/${proposalId}/render`, {responseType: "blob"})
        return response.data;
    },
    async generateProposalFromRequirements(requirementsObject) {
        const response = await axios.post(`/proposal/generate`, requirementsObject)
        return response.data;
    },
}

export default api;
