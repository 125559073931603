import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
let globalStorySchema = null;

const api = {
    async getStorySchema() {
        if (globalStorySchema) {
            return globalStorySchema;
        }
        const response = await axiosWithETAGCache.get(`/story/schema`);
        globalStorySchema = response.data;
        return response.data;
    },
    async getStoryPromptConfiguration() {
        const response = await axios.get(`/story_prompt_configuration`);
        return response.data;
    },
    async saveStoryPromptConfiguration(data) {
        const response = await axios.put(`/story_prompt_configuration`, data);
        return response.data;
    },
    async generateStory(data) {
        const response = await axios.post(`/story`, data);
        return response.data;
    },
    async downloadPDFForStory(story) {
        const response = await axios.post(`/story/pdf`, story, {responseType: "blob"})
        return response.data;
    },
    async getStorys(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/story`, {
            params: queryParams
        });

        return response.data;
    },
    async getStory(storyId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/story/${storyId}`, {
            params: queryParams
        });

        return response.data;
    },
    async deleteStory(storyId) {
        const response = await axios.delete(`/story/${storyId}`);

        return response.data;
    },
}

export default api;
