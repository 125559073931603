import React from 'react';


export const ElementVisibilityDelayer = ({children, delayMs}) => {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => setVisible(true), delayMs);
        return () => clearTimeout(timeout);
    }, [delayMs]);

    return visible ? children : null;
}
