import axios from "axios";
import SSEStreamer from "../../../core/frontend/components/SSEStreamer";
import config from "../../../core/frontend/components/config";

const api = {
    async newMessage(conversation_id, message, conversation_token) {
        const newMessageData = {
            content: message,
            conversation_token: conversation_token,
        }

        const response = await axios.post(`/conversation/${conversation_id}/message`, newMessageData);

        return response.data;
    },
    streamChatEvents(converation_id, conversation_token, mostRecentEventTime) {
        const apiUrl = String(config.REACT_APP_BACKEND_API_URL);
        let streamUri;
        if (apiUrl.endsWith("/")) {
            streamUri = apiUrl + `conversation/${converation_id}/message_stream`;

        } else {
            streamUri = apiUrl + `/conversation/${converation_id}/message_stream`;
        }

        if (conversation_token) {
            if (streamUri.includes("?")) {
                streamUri = streamUri + '&';
            } else {
                streamUri = streamUri + '?';
            }
            streamUri = streamUri + `conversation_token=${conversation_token}`;
        }

        if (mostRecentEventTime) {
            if (streamUri.includes("?")) {
                streamUri = streamUri + '&';
            } else {
                streamUri = streamUri + '?';
            }
            streamUri = streamUri + `since=${encodeURIComponent(mostRecentEventTime)}`;
        }

        // Make the streaming call with the same headers as a normal axios call, which will include the
        // authentication token if it's available
        const headers = axios.defaults.headers.common;

        // Construct the SSE streamer and return it.
        return new SSEStreamer(streamUri, "GET", null, headers);
    },
    async getWebChatConversation(conversationId, conversationToken) {
        const response = await axios.get(`/web/conversation/${conversationId}`, {
            params: {
                conversation_token: conversationToken
            }
        });
        return response.data;
    },
    async getWebChatConfiguration() {
        const response = await axios.get(`/web/configuration`);
        return response.data;
    },
    async startWebChatConversation(data) {
        const response = await axios.post(`/web/conversation`, data);

        return response.data;
    },
}

export default api;
