import axios from "axios";
import {ensureMinimumPromiseResolveTime} from "../../core/frontend/utils/time";
import {axiosETAGCache} from "axios-etag-cache";

let globalContentGeneratorOrderSchema = null;
const axiosWithETAGCache = axiosETAGCache(axios);


const api = {
    async getContentGeneratorOrderSchema() {
        if (globalContentGeneratorOrderSchema) {
            return globalContentGeneratorOrderSchema;
        }
        const response = await axiosWithETAGCache.get(`/content_generator_order/schema`);
        globalContentGeneratorOrderSchema = response.data;
        return response.data;
    },
    async submitContentGeneratorOrder(orderData) {
        const body = orderData;

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/content_generator_order`, body), 1000);

        return response.data;
    },
    async getContentGeneratorOrders(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axios.get(`/content_generator_order`, {
            params: queryParams
        });

        return response.data;
    },
    async getContentGeneratorOrder(contentGeneratorOrderId) {
        const queryParams = {}

        const response = await axios.get(`/content_generator_order/${contentGeneratorOrderId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveContentGeneratorOrder(contentGeneratorOrder) {
        const response = await axios.put(`/content_generator_order/${contentGeneratorOrder._id}`, contentGeneratorOrder);
        return response.data;
    },
    async deleteContentGeneratorOrder(contentGeneratorOrderId) {
        const response = await axios.delete(`/content_generator_order/${contentGeneratorOrderId}`);

        return response.data;
    },
}

export default api;
