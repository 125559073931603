import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";


// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getOnboardingChecklist() {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/onboarding`, {
            params: queryParams
        });

        return response.data;
    },
}

export default api;