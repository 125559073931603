import React, {useContext, useEffect} from "react";
import {useAPI} from "./APIProvider";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";
import config from "../../../core/frontend/components/config";

const FeaturesContext = React.createContext(null);

let globalCachedEnabledFeatures = null;
if (config.REACT_APP_ENABLED_FEATURES_JSON &&
    config.REACT_APP_ENABLED_FEATURES_JSON !== "null" &&
    config.REACT_APP_ENABLED_FEATURES_JSON !== "undefined") {
    globalCachedEnabledFeatures = JSON.parse(config.REACT_APP_ENABLED_FEATURES_JSON);
}

/**
 * The Features provider just returns a cached version of the response from the features endpoint,
 * which is used to determine which features are enabled in the application
 */
export const FeaturesProvider = ({children}) => {
    const api = useAPI();
    const [enabledFeatures, setEnabledFeatures] = React.useState(globalCachedEnabledFeatures);

    useEffect(() => {
        if (!enabledFeatures) {
            api.features().then((enabledFeatures) => {
                setEnabledFeatures(enabledFeatures);
                globalCachedEnabledFeatures = enabledFeatures;
            });
        }
    }, [enabledFeatures, api]);

    if (!enabledFeatures) {
        return <ApplicationBootingPage />
    }

    return <FeaturesContext.Provider value={enabledFeatures}>
        {children}
    </FeaturesContext.Provider>;
};

export const useFeatures = useContext.bind(null, FeaturesContext);

