import CircularProgress from "@mui/material/CircularProgress";
import {PageWrapperWithoutMenu} from "../elements/PageWrapperWithoutMenu";
import {ElementVisibilityDelayer} from "../components/ElementVisibilityDelayer";
import React from "react";

let globalBootupCountdown = 90;

export const ApplicationBootingPage = () => {
    const [countdown, setCountdown] = React.useState(globalBootupCountdown);

    React.useEffect(() => {
        const interval = setInterval(() => {
            globalBootupCountdown -= 1;
            setCountdown(globalBootupCountdown);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return <PageWrapperWithoutMenu>
        <ElementVisibilityDelayer delayMs={750}>
            <div>
                {
                    globalBootupCountdown <= 1 ?
                        <p>There may be a problem with the server boot sequence. Please contact support.</p> :
                        <div>
                            <p>Application is booting up ...</p>
                            <p>Please wait</p>
                            {
                                globalBootupCountdown <= 80 ?
                                    <p>This may take up to {countdown} more seconds ...</p>
                                : null
                            }
                            <CircularProgress/>
                        </div>
                }
            </div>
        </ElementVisibilityDelayer>
    </PageWrapperWithoutMenu>
}

export default ApplicationBootingPage;
