import axios from "axios";

const api = {

    async uploadFile(file, onUploadProgress) {
        const formData = new FormData();
        formData.append(file.name, file);

        let handle = null;

        const options = {
            onUploadProgress: function (progressEvent) {
                const proportionCompleted = progressEvent.loaded / progressEvent.total;
                if (onUploadProgress) {
                    onUploadProgress(proportionCompleted * 0.5);
                }

                if (progressEvent.loaded === progressEvent.total) {
                    if (onUploadProgress) {
                        onUploadProgress(0.5);
                    }

                    let progress = 0.5;
                    // Now start an interval to update the progress bar until the file finishes getting processed.
                    handle = setInterval(async () => {
                        progress += (1.0 - progress) * 0.025;
                        if (onUploadProgress) {
                            onUploadProgress(progress);
                        }
                    }, 250);
                }
            },
            onDownloadProgress: function (progressEvent) {
                // console.log(progressEvent.loaded, progressEvent.total);
                if (progressEvent.loaded === progressEvent.total) {
                    if (handle) {
                        clearInterval(handle);
                    }
                    if (onUploadProgress) {
                        onUploadProgress(1.0);
                    }
                }
            }
        }

        const response = await axios.post(`/file`, formData, options);

        return response.data;
    },
}

export default api;