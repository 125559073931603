import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import "./AccountMenu.scss";
import { useAuth0 } from "@auth0/auth0-react";
import md5Hex from 'md5-hex';
import { useCallback } from 'react';
import {useAPI} from "../components/APIProvider";
import PaymentsIcon from '@mui/icons-material/Payments';
import SellIcon from '@mui/icons-material/Sell';
import {NavLink} from "react-router-dom";
import config from "../../../core/frontend/components/config";
import LocalTranslatedText from "../../translation/frontend/components/local_translated_text";


export default function AccountMenu() {
    const { loginWithPopup, logout, user, isAuthenticated } = useAuth0();
    const api = useAPI();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleLoginClick = useCallback(() => {
        loginWithPopup();
    }, [loginWithPopup]);

    const handleLogoutClick = useCallback(() => {
        handleClose();
        logout({ logoutParams: { returnTo: window.location.origin } });
    }, [handleClose, logout]);

    const handleBillingClick = useCallback(() => {
        handleClose();
        api.getBookingPortalUrl().then((data) => {
            const url = data.url;

            // Redirect user over to the booking portal
            window.location.href = url;
        });
    }, [handleClose, api]);

    // Generates the gravatar url for the given email address by trimming, lowercasing and md5ing it
    let gravatarUrl = null;
    if (user) {
        gravatarUrl = `https://www.gravatar.com/avatar/${md5Hex(user?.email?.trim().toLowerCase())}.jpg?d=mp`;
    }

    let avatarStyle = {
        width: 32,
        height: 32,
    }

    if (user) {
        avatarStyle.bgcolor = "#09a60c";
    }

    return (
        <div className={"account-menu"}>
            <React.Fragment>
                <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                    <Tooltip title={<LocalTranslatedText language={"en"} text="Account" />}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ml: 2}}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={avatarStyle} src={gravatarUrl}>
                                {
                                    user ? <span className={"avatar-text"}>{user?.given_name?.substring(0, 1)}{user?.family_name?.substring(0, 1)}</span> : null
                                }
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    classes={
                        {
                            paper: "account-menu-paper"
                        }
                    }
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                >
                    {
                        !isAuthenticated ?
                            <MenuItem onClick={handleLoginClick}>
                                <Avatar/> <LocalTranslatedText language={"en"} text="Login" />
                            </MenuItem>
                            : null
                    }
                    {
                        isAuthenticated ?
                            <MenuItem>
                                {user?.name}
                            </MenuItem>
                            : null
                    }
                    {
                        isAuthenticated ?
                            <MenuItem>
                                {user?.email}
                            </MenuItem>
                            : null
                    }
                    {
                        isAuthenticated && config.REACT_APP_ENABLE_BILLING_IN_ACCOUNT_MENU === 'true' ?
                            <MenuItem onClick={handleBillingClick}>
                                <ListItemIcon>
                                    <PaymentsIcon fontSize="small"/>
                                </ListItemIcon>
                                <LocalTranslatedText language={"en"} text="Billing & Payments" />
                            </MenuItem> : null
                    }
                    {
                        isAuthenticated && config.REACT_APP_ENABLE_BILLING_IN_ACCOUNT_MENU === 'true' ?
                        <NavLink
                            to={`/subscription`}
                            className={({isActive, isPending}) => {
                                return isActive ? "active" : isPending ? "pending" : "";
                            }}
                        >
                            <MenuItem>
                                    <ListItemIcon>
                                        <SellIcon fontSize="small"/>
                                    </ListItemIcon>
                                    <span className={"subscription-text"}>
                                        <LocalTranslatedText language={"en"} text="Subscription" />
                                    </span>
                            </MenuItem>
                       </NavLink> : null
                    }
                    {
                        isAuthenticated ?
                            <MenuItem onClick={handleLogoutClick}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem> : null
                    }
                </Menu>
            </React.Fragment>
        </div>
    );
}