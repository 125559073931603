import {PageWrapperWithoutMenu} from "../elements/PageWrapperWithoutMenu";
import React, {useEffect} from "react";
import "./LoginPage.scss";
import CircularProgress from "@mui/material/CircularProgress";
import {useAuth0} from "@auth0/auth0-react";
import {ElementVisibilityDelayer} from "../components/ElementVisibilityDelayer";


export function LoginPage() {
    const { loginWithRedirect } = useAuth0();


    useEffect(() => {
        // Check if there is a query parameter for code in the page URL. If so, we just need
        // to wait for a second for auth0 code to kick in and process the login. If not, we
        // can kick off a new login.
        if (!window.location.search.includes("code=")) {
            let timeout = setTimeout(() => {
                localStorage.setItem("prospera-login-redirect-path", window.location.pathname);
                loginWithRedirect();
            }, 750);
            return () => {
                clearTimeout(timeout);
            }
        }
    }, [loginWithRedirect]);

    return <PageWrapperWithoutMenu>
        <ElementVisibilityDelayer delayMs={750}>
            <div className={"login-page"}>
                <p>Logging in...</p>

                <CircularProgress />
            </div>
        </ElementVisibilityDelayer>
    </PageWrapperWithoutMenu>
}

