import {TopBar} from "../menu/TopBar";
import React from "react";
import "./PageWrapperWithoutMenu.scss";

export const PageWrapperWithoutMenu = ({children}) => {
    return <div className="page-wrapper-without-menu">
        <TopBar/>
        <div className={"below-top-bar"}>

            <div className={"main-content-area"}>
                {children ?? null}
            </div>
        </div>
    </div>
};