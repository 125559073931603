const config = {};

/**
 * Load in the keys dynamically from the process env.
 * This ensures that the frontend compiler optimizer
 * doesn't attempt to automatically resolve any conditionals
 * that involve these environment variables.
 * **/
for (let key of Object.keys(process.env)) {
    if (key.startsWith("REACT_APP_")) {
        config[key] = process.env[key];
    }
}

export default config;
