import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

const api = {
    async getInsuranceAgentSchema() {
        const response = await axiosWithETAGCache.get(`/insurance_agent/schema`);
        return response.data;
    },
    async getInsuranceAgents(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        };

        const response = await axiosWithETAGCache.get(`/insurance_agent`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewInsuranceAgent(newAgentData) {
        const response = await axios.post(`/insurance_agent`, newAgentData);

        return response.data;
    },
    async getInsuranceAgent(insuranceAgentsId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/insurance_agent/${insuranceAgentsId}`, {
            params: queryParams
        });

        return response.data;
    },
    async deleteInsuranceAgent(insuranceAgentsId) {
        const response = await axios.delete(`/insurance_agent/${insuranceAgentsId}`);

        return response.data;
    },
    async saveInsuranceAgent(insuranceAgentData) {
        const response = await axios.put(`/insurance_agent/${insuranceAgentData._id}`, insuranceAgentData);
        return response.data;
    },
}

export default api;