import axios from "axios";
import {ensureMinimumPromiseResolveTime} from "../../../core/frontend/utils/time";
import SSEStreamer from "../../../core/frontend/components/SSEStreamer";
import {axiosETAGCache} from "axios-etag-cache";
import config from "../../../core/frontend/components/config";

let globalPromptChartSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);


const api = {
    async getSingleChartEvaluation(singleChartEvaluationId) {
        const body = {}

        const response = await axiosWithETAGCache.get(`/single_chart_evaluation/${singleChartEvaluationId}`, body);

        return response.data;
    },
}

export default api;
