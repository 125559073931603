import React from 'react';
import "./OnboardingPopupWrapper.scss";
import OnboardingChecklist from "./OnboardingChecklist";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import config from "../../../core/frontend/components/config";
import Paper from "@mui/material/Paper";

export default function OnboardingPopupWrapper({children}) {
    const api = useAPI();
    const [checklistData, setChecklistData] = React.useState(null);

    React.useEffect(() => {
        if (config.REACT_APP_ENABLE_ONBOARDING === "true") {
            api.getOnboardingChecklist().then((checklistData) => {
                let allComplete = true;
                checklistData.items.map((item) => {
                    if (!item.is_completed) {
                        allComplete = false;
                    }
                });
                // Only show the checklist in the popup if not all the items have been completed
                if (!allComplete) {
                    setChecklistData(checklistData);
                }
            });
        }
    }, [api]);

    if (config.REACT_APP_ENABLE_ONBOARDING === "false") {
        return children;
    }

    return <>
        {
            checklistData ?
                <Paper className={"onboarding-popup-wrapper"}>
                    <OnboardingChecklist
                        checklistData={checklistData}
                        defaultIsExpanded={false}
                    />
            </Paper> : null
        }
        {children}
    </>
}