
import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

const api = {
    async getConversationSchema(schema) {
        if (schema) {
            return schema;
        }
        const response = await axiosWithETAGCache.get(`/conversation/schema`);
        return response.data;
    },
    async getConversations(tableQuery) {
        const queryParams = {
            query: JSON.stringify(tableQuery)
        }

        const response = await axiosWithETAGCache.get(`/conversation`, {
            params: queryParams
        });

        return response.data;
    },
    async createNewConversation(data) {
        const response = await axios.post(`/conversation`, data);

        return response.data;
    },
    async getConversation(conversationId) {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/conversation/${conversationId}`, {
            params: queryParams
        });

        return response.data;
    },
    async saveConversation(conversation) {
        const response = await axios.put(`/conversation/${conversation._id}`, conversation);
        return response.data;
    },
    async deleteConversation(conversationId) {
        const response = await axios.delete(`/conversation/${conversationId}`);

        return response.data;
    },
    async sendInitialMessageForConversation(conversationId) {
        const response = await axios.post(`/conversation/${conversationId}/send_initial`);

        return response.data;
    },
    async getRecentEvents(conversation_id, conversationToken) {
        const queryParams = {};

        if (conversationToken) {
            queryParams.conversation_token = conversationToken;
        }

        const response = await axiosWithETAGCache.get(`/conversation/${conversation_id}/recent_messages`, {
            params: queryParams
        });

        return response.data;
    },
    async checkFollowUp(conversation_id) {
        const queryParams = {}

        const response = await axios.post(`/conversation/${conversation_id}/follow_up`, {
            params: queryParams
        });

        return response.data;
    },
    async downloadConversationExport(conversationId) {
        const response = await axios.get(`/conversation/${conversationId}/pdf`, {responseType: "blob"})
        return response.data;
    },
    async downloadConversationExportPublic(conversationId, conversationToken) {
        const queryParams = {
            conversation_token: conversationToken
        }

        const response = await axios.get(`/conversation/${conversationId}/pdf`, {
            responseType: "blob",
            params: queryParams
        })
        return response.data;
    },
}

export default api;
