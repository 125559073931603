import axios from "axios";
import {axiosETAGCache} from "axios-etag-cache";

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getBusinessInfo() {
        const queryParams = {}

        const response = await axiosWithETAGCache.get(`/business_info`, {
            params: queryParams
        });

        return response.data;
    },
    async saveBusinessInfo(businessInfo) {
        const response = await axios.put(`/business_info`, businessInfo);
        return response.data;
    },
    async getBusinessInfoFromWebsite(websiteUrl) {
        const response = await axios.get(`/business_info/extract`, {params: {url: websiteUrl}});
        return response.data;
    },
}

export default api;