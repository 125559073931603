import {TopBar} from "../menu/TopBar";
import LeftSideMenu from "../menu/LeftSideMenu";
import React from "react";
import "./PageWrapperWithMenu.scss";

export const PageWrapperWithMenu = ({children, padding}) => {
    if (padding === undefined) {
        padding = true;
    }

    const mainContentAreaClassName = `main-content-area ${padding ? "padding" : "no-padding"}`
    const pageBodyClassName = `page-body ${padding ? "padding" : "no-padding"}`


    return <div className="page-wrapper-with-menu">
        <TopBar/>
        <div className={"below-top-bar"}>

            <LeftSideMenu/>

            <div className={mainContentAreaClassName}>
                <div className={pageBodyClassName}>
                    {children}
                </div>
            </div>
        </div>
    </div>
};