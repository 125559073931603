import axios from "axios";

const api = {
    async getAvailableActions(conversation_id) {
        const response = await axios.get(`/conversation/${conversation_id}/available_actions`);
        return response.data;
    },
    async getLabelForConversationEvent(conversation_event_id) {
        const response = await axios.get(`/conversation_event/${conversation_event_id}/label`);
        return response.data;
    },
    async setLabelForConversationEvent(conversation_event_id, label_data) {
        const response = await axios.put(`/conversation_event/${conversation_event_id}/label`, label_data);
        return response.data;
    },
    async getLabelForConversation(conversation_id) {
        const response = await axios.get(`/conversation/${conversation_id}/label`);
        return response.data;
    },
    async setLabelForConversation(conversation_id, label_data) {
        const response = await axios.put(`/conversation/${conversation_id}/label`, label_data);
        return response.data;
    },
    async getConversationsWithLabels() {
        const response = await axios.get(`/conversations_with_labels`);
        return response.data;
    },
}

export default api;