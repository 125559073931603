import React from "react";
import "./PageHeader.scss";
import { Typography } from "@mui/material";
import LocalTranslatedText from "../../translation/frontend/components/local_translated_text";

export const PageHeader = ({ title, children, className }) => {
  if (!className) {
    className = "";
  }

  return (
    <div className={`page-header ${className}`}>
      <Typography variant={"h1"}>
        <LocalTranslatedText language={"en"} text={title} />
      </Typography>
      {children}
    </div>
  );
};
