import React, {useEffect} from "react";
import {useAPI} from "./APIProvider";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";
import config from "../../../core/frontend/components/config";

/**
 * The wakeup provider ensures that the backend server is awake. This ensures we don't
 * try to interact with a backend server if it's currently starting up (which often
 * happens if the backend scaled down to zero).
 */
export const WaitForBackendAwakeWrapper = ({children}) => {
    const [isBooted, setIsBooted] = React.useState(config.REACT_APP_WAIT_FOR_BACKEND_SERVER_WAKE_UP === "false");
    const api = useAPI();

    useEffect(() => {
        if (config.REACT_APP_WAIT_FOR_BACKEND_SERVER_WAKE_UP === "true") {
            // We call the home endpoint, and wait until it responds
            // before declaring the backend is booted.
            console.log("Making first api call");
            api.home().then(() => {
                console.log("Making second api call");
                api.home().then(() => {
                    console.log("Fully Booted");
                    setIsBooted(true);
                });
            });
        }
    }, [api]);

    if (!isBooted) {
        return <ApplicationBootingPage />;
    } else {
        return <>
            {children}
        </>;
    }
};

